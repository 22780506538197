<template>
  <v-container>
    <v-toolbar dense flat>
      <template v-if="isAdmin">
        <v-btn outlined :to="{params: { id: null}}">
          Create New Component
        </v-btn>
        <v-spacer/>
      </template>
      <template v-if="!isAdmin">
        <v-btn outlined :to="{ name:'ComponentDetailAdmin', params: { id: componentId}}">
          Go to Edit Mode
        </v-btn>
        <v-spacer/>
      </template>
      <v-checkbox
          label="Normalise to 100g"
          v-model="normaliseTo100g"
      />
    </v-toolbar>
    <ComponentDetail
        :componentId="componentId"
        v-on:update:componentId="$router.push({params: { id: $event}})"
        :read-only="!isAdmin || readOnly"
        :is-admin="isAdmin"
        :normalise-to100g="normaliseTo100g"
    />
  </v-container>
</template>

<script>
import ComponentDetail from '@/components/ComponentDetail';
import urlState from "@/router/urlState";


export default {
  mixins: [urlState],
  created() {
    this.readOnly = !!this.$route.meta.readOnly;
  },
  mounted() {
    this.syncToUrl({
      param: 'normaliseTo100g', urlParam: 'normalise', initFromRoute: true,
      parseCallback: (v) => v && v === 'true'
    });
  },
  data() {
    return {
      dialog: true,
      readOnly: true,
      normaliseTo100g: true
    }
  },
  components: {
    ComponentDetail,
  },
  computed: {
    componentId() {
      return this.$route.params.id && parseInt(this.$route.params.id);
    },
  },
  methods: {
    newComponentRoute() {
      const newRoute = {name: this.$route.name, params: {id: null}};
      console.log('new route', newRoute);
      return newRoute;
    }
  },
  props: {
    isAdmin: {type: Boolean, default: false}
  }
}
</script>
